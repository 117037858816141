import React, { useState, useEffect } from "react"
import { copyToClipboard, getCurrentBreakpoint, useStorage } from "../../utils/Helper"
import ModalContainer from "../modals/ModalContainer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMemo } from "react"
import { _getCookie } from "../../utils/Cookie"
import classes from './ShareDropdown.module.css'

interface IProps {
    title?: string
    description?: string
    /** custom url if it's not for current page url */
    uri?: string
    buttonType?: number
    renderButton?: (toggleDropdown: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void, toggleModal: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) => React.ReactElement
    setIsShareOpen?: (isOpen: boolean) => void
    rootClassName?: string
    withoutMX3?: boolean
}

const ShareDropdown = ({ title, description, uri, buttonType, setIsShareOpen, renderButton, rootClassName, withoutMX3 }: IProps) => {
    const [isOpen, setOpen] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false)
    const [url, setUrl] = useState('')
    const [shareimg, setShareimg] = useState('/static/images/svg/share-alt.svg')
    const [imgFilter, setImgFilter] = useState(true)
    const [isMobile, setIsMobile] = useState(false)
    
    const handleMouseOver = () => {
        if (!isMobile) {
            setShareimg('/static/images/svg/share-white-alt.svg');
            setImgFilter(false)
        }
    }
     
    const handleMouseLeave= () => {
        if (!isMobile) {
            setShareimg('/static/images/svg/share-alt.svg');
            setImgFilter(true)
        }
    }

    const [refCode, setRefCode] = useState('')

    const finalUrl = useMemo(() => url.replace('bsd-residential', 'bsd-city').replace('bsd-commercial', 'bsd-city') + (!!refCode && !url.includes(refCode) ? `?rc=${refCode}` : ''), [url, refCode])

    useEffect(() => {
        window.addEventListener('click', closeDropDown)
        setUrl(uri || window.location.href)

        useStorage(() => {
            const localStorageRefCode = localStorage.getItem('rc')
            if (localStorageRefCode) {
                setRefCode(localStorageRefCode)
            } else {
                const cookieRefCode = _getCookie('rc_cookie')
                if (cookieRefCode) {
                    setRefCode(cookieRefCode)
                }
            }
        })
        
        return () => {
            window.removeEventListener('click', closeDropDown)
        }
    }, [])

    const toggleDropDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        setOpen(prev => !prev)
    }

    const toggleModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        if (!!navigator.share) {
            navigator.share({
                title: title,
                text: description,
                url: finalUrl,
            })
        } else {
            setModalOpen(prev => !prev)
            if (setIsShareOpen) setIsShareOpen(!isOpen)
        }
    }

    const closeDropDown = () => {
        setOpen(false)
    }

    const closeModal = () => {
        setModalOpen(false)
        if (setIsShareOpen) setIsShareOpen(false)
    }

    useEffect(() => {
        let breakpoint = getCurrentBreakpoint()
        setIsMobile(breakpoint === 'xs' || breakpoint === 'sm')
    }, [])

    return (
        <div className={`relative ${buttonType === 3 ? '' : !withoutMX3 ? 'mx-3' : ''} ${rootClassName}`}>
            {
                buttonType === 2 || buttonType === 3 ?
                    <>
                        <button onClick={toggleDropDown} className="hidden lg:flex bg-white text-main items-center px-6 py-2 text-base font-normal focus:outline-none">
                            <img loading="lazy" width="20" height="20" src="/static/images/svg/share-alt.svg" alt="Share" className={`mr-2 ${classes['icon-filter']}`}/>
                            <p className="text-sm font-semibold">Share</p>
                        </button>
                        <button onClick={toggleModal} className={`flex lg:hidden bg-white text-main items-center ${buttonType === 3 ? 'px-2' : 'px-6'} py-2 text-base font-normal focus:outline-none`}>
                            <img loading="lazy" width="20" height="20" src="/static/images/svg/share-alt.svg" alt="Share" className={`mr-2 ${ classes['icon-filter'] }`} />
                            <p className="text-sm font-semibold">Share</p>
                        </button>
                    </>
                    :
                    buttonType === 4 ?
                        <>
                            <button onClick={toggleDropDown} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave} className={`${!isMobile ? 'hover:bg-main-dark hover:text-white ' : '' } hidden lg:flex bg-white text-main rounded border border-red-e3b670 items-center px-6 py-2 mx-auto text-base font-normal focus:outline-none`}>
                                <img loading="lazy" width="20" height="20" src={shareimg} alt="Share" className={`mr-2 ${imgFilter === true ? classes['icon-filter'] : ''}`} />
                                <p className="text-sm font-semibold">Share Cluster</p>
                            </button>
                            <button onClick={toggleModal} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave} className={`${!isMobile ? 'hover:bg-main-dark hover:text-white ' : '' } flex lg:hidden bg-white text-main rounded border border-red-e3b670 items-center px-6 py-2 mx-auto text-base font-normal focus:outline-none`}>
                                <img loading="lazy" width="20" height="20" src={shareimg} alt="Share" className={`mr-2 ${imgFilter === true ? classes['icon-filter'] : ''}`} />
                                <p className="text-sm font-semibold">Share Cluster</p>
                            </button>
                        </>
                        :
                        renderButton ?
                            renderButton(toggleDropDown, toggleModal)
                            :
                            <>
                                <button onClick={toggleDropDown} className="hidden lg:flex rounded focus:shadow-outline bg-white rounded text-black-48 bg-gray-fbfb items-center px-6 py-2 shadow-xl text-white text-base font-normal focus:outline-none">
                                    <img loading="lazy" width="20" height="20" src="/static/images/svg/share-alt.svg" alt="Share" className="mr-2" />
                                    <p className="text-sm text-black-48">Share</p>
                                </button>
                                <button onClick={toggleModal} className="flex items-center lg:hidden hover:text-main active:text-main mr-4 text-white text-base font-normal focus:outline-none">
                                    <img loading="lazy" width="20" height="20" src="/static/images/svg/share-white-alt.svg" alt="Share" className="w-6" />
                                </button>
                            </>
            }
            <div className={`absolute text-sm bottom-0 right-0 -mb-2 bg-white shadow transform translate-y-full rounded ${ isOpen ? 'hidden lg:block' : 'hidden' } z-5 ${ classes['w-max-content'] }`}>
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${finalUrl}`} target="__blank" className="flex flex-row border-b rounded-t py-2 px-3 hover:bg-blue-e6 items-center focus:outline-none pr-16">
                    <img loading="lazy" width="20" height="20" src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/facebook-alt.svg`} alt="facebook" className={`mr-3 ${ classes['h-15px'] }`} />
                    <p>Facebook</p>
                </a>
                <a href={`https://twitter.com/intent/tweet?url=${finalUrl}`} target="__blank" className="flex flex-row border-b py-2 px-3 hover:bg-blue-e6 items-center focus:outline-none pr-16">
                    <img loading="lazy" width="20" height="20" src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/twitter.svg`} alt="twitter" className={`mr-3 ${ classes['h-15px'] }`} />
                    <p>Twitter</p>
                </a>
                <a href={`https://api.whatsapp.com/send?text=${finalUrl}`} target="__blank" className="flex flex-row border-b py-2 px-3 hover:bg-blue-e6 items-center focus:outline-none pr-16">
                    <img loading="lazy" width="20" height="20" src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/whatsapp-border-alt.svg`} alt="whatsapp" className={`mr-3 ${ classes['h-15px'] }`} />
                    <p>Whatsapp</p>
                </a>
                {/* <a href={`mailto:?body=${finalUrl}${title ? `&subject=${title}` : ''}`} target="__blank" className="flex flex-row border-b py-2 px-3 hover:bg-blue-e6 items-center focus:outline-none pr-16">
                    <img loading="lazy" width="20" height="20" src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/mail.svg`} alt="email" className={`mr-3 ${ classes['h-15px'] }`} />
                    <p>Email</p>
                </a> */}
                <span onClick={() => copyToClipboard(finalUrl)} className="flex flex-row py-2 px-3 hover:bg-blue-e6 hover:border-0 rounded-b items-center focus:outline-none cursor-pointer pr-16">
                    <img loading="lazy" width="20" height="20" src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/link.svg`} alt="link" className={`mr-3 ${ classes['h-15px'] }`} />
                    <p>Copy Link</p>
                </span>
            </div>
            {
                <span className="lg:hidden">
                    <ModalContainer
                        isOpen={isModalOpen}
                        fullScreen={true}
                        close={closeModal}
                        noPadding
                    >
                        <div className="bg-white">
                            <div className="flex items-center justify-center relative">
                                <h4 className="text-2xl text-black-48 py-4 font-light">Share</h4>
                                <FontAwesomeIcon onClick={closeModal} icon="times" width="0" className="absolute right-0 mr-5" />
                            </div>
                            <div className="flex flex-col">
                                <a href={`https://www.facebook.com/sharer/sharer.php?u=${finalUrl}`} target="__blank" className="flex flex-row border-b rounded-t py-4 px-6 hover:bg-blue-e6 items-center focus:outline-none pr-16">
                                    <img loading="lazy" width="20" height="20" src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/facebook-alt.svg`} alt="facebook" className="mr-3 h-5" />
                                    <p>Facebook</p>
                                </a>
                                <a href={`https://twitter.com/intent/tweet?url=${finalUrl}`} target="__blank" className="flex flex-row border-b py-4 px-6 hover:bg-blue-e6 items-center focus:outline-none pr-16">
                                    <img loading="lazy" width="20" height="20" src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/twitter.svg`} alt="twitter" className="mr-3 h-5" />
                                    <p>Twitter</p>
                                </a>
                                <a href={`https://api.whatsapp.com/send?text=${finalUrl}`} target="__blank" className="flex flex-row border-b py-4 px-6 hover:bg-blue-e6 items-center focus:outline-none pr-16">
                                    <img loading="lazy" width="20" height="20" src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/whatsapp-border-alt.svg`} alt="whatsapp" className="mr-3 h-5" />
                                    <p>Whatsapp</p>
                                </a>
                                {/* <a href={`mailto:?body=${finalUrl}${title ? `&subject=${title}` : ''}`} target="__blank" className="flex flex-row border-b py-4 px-6 hover:bg-blue-e6 items-center focus:outline-none pr-16">
                                    <img loading="lazy" width="20" height="20" src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/mail.svg`} alt="email" className="mr-3 h-5" />
                                    <p>Email</p>
                                </a> */}
                                <span onClick={() => copyToClipboard(finalUrl)} className="flex flex-row py-4 px-6 hover:bg-blue-e6 border-b rounded-b items-center focus:outline-none cursor-pointer pr-16">
                                    <img loading="lazy" width="20" height="20" src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/link.svg`} alt="link" className="mr-3 h-5" />
                                    <p>Copy Link</p>
                                </span>
                            </div>
                        </div>
                    </ModalContainer>
                </span>
            }
        </div>
    )
}

export default ShareDropdown
